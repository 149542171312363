/*
    SINCE THERE IS AMBIGUITY VIA HAVING THE MARGIN TOP FOR THE HEADER AS IT CONSIST OF LOGO, SEARCH BAR, TABS
    WHICH HAS THEIR OWN HEIGHTS HENCE DOING IT VIA CSS IS TRICKY
    AS THE HEIGHT ARE CONSTANT AND DOESN'T CHANGE OFTEN CALCULATING THE HEIGHT VIA DIFFERENT PARAMS
*/
export default (headerProps = {}, _, layoutStyle = {}) => {
    const mobileMapping = {
        headerH: 56, searchBarH: 48, subHeaderH: 44
    };
    let finalHeight = mobileMapping.searchBarH;

    if (headerProps.isVisible) {
        finalHeight += mobileMapping.headerH;
    }
    if (headerProps.headerProps?.enableSearchInput === false) {
        finalHeight -= mobileMapping.searchBarH;
    }

    const m = {
        marginTop: `${finalHeight}px`
    };

    return {
        ...m, ...layoutStyle
    };
};
